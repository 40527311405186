import React from 'react'
import { Link } from 'gatsby'
import StepList from './StepsList'

function AnleitungsList({ anleitungen, showStepsList }) {

  let anleitungsDauer = 0

  return (
    <ol>
      {anleitungen.map((anleitung) => {
        anleitung.anleitungsDauer = anleitungsDauer

        if (!anleitung.anleitung.document) return null
        // calc duration of each anleitung
        {
          anleitung?.anleitung?.document?.data?.steps?.map((step) => {
            if (!step.active) return null
            return (anleitungsDauer += step.duration_mm)
          })
        }
        return (
          <li className="fs-5" key={anleitung.index}>
            <Link to={`/${anleitung.anleitung.document.uid}`}>
              <p className="fs-5">
                {anleitung?.anleitung?.document?.data?.title.text}
                <span> ({anleitungsDauer - anleitung.anleitungsDauer}`)</span>
              </p>
            </Link>
            <StepList
              steps={anleitung?.anleitung?.document?.data?.steps}
              showStepsList={showStepsList}
            />
          </li>
        )
      })}
    </ol>
  )
}

export default AnleitungsList
